import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "download-do-cmr-protected"
    }}>{`Download do CMR Protected`}</h1>
    <p>{`Selecione a opção de download adequada para o seu servidor MTA.`}</p>
    <h2 {...{
      "id": "windows"
    }}>{`Windows`}</h2>
    <h3 {...{
      "id": "x64"
    }}>{`x64`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Versão:`}</strong>{` 1.2.0`}</li>
      <li parentName="ul"><strong parentName="li">{`Atualizado em:`}</strong>{` 15 de julho de 2024`}</li>
    </ul>
    <p>{`Faça o download da versão para Windows x64 `}<a parentName="p" {...{
        "href": "https://storage.cmr.dev.br/cmr-protected-win-x64.zip?_=1721055600"
      }}>{`aqui`}</a>{`.`}</p>
    <h3 {...{
      "id": "x86"
    }}>{`x86`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Versão:`}</strong>{` 1.2.0`}</li>
      <li parentName="ul"><strong parentName="li">{`Atualizado em:`}</strong>{` 15 de julho de 2024`}</li>
    </ul>
    <p>{`Faça o download da versão para Windows x86 `}<a parentName="p" {...{
        "href": "https://storage.cmr.dev.br/cmr-protected-win-x86.zip?_=1721055600"
      }}>{`aqui`}</a>{`.`}</p>
    <h2 {...{
      "id": "linux"
    }}>{`Linux`}</h2>
    <h3 {...{
      "id": "x64-1"
    }}>{`x64`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Versão:`}</strong>{` 1.2.0`}</li>
      <li parentName="ul"><strong parentName="li">{`Atualizado em:`}</strong>{` 15 de julho de 2024`}</li>
    </ul>
    <p>{`Faça o download da versão para Linux x64 `}<a parentName="p" {...{
        "href": "https://storage.cmr.dev.br/cmr-protected-linux-x64.zip?_=1721055600"
      }}>{`aqui`}</a>{`.`}</p>
    <h3 {...{
      "id": "x86-1"
    }}>{`x86`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Versão:`}</strong>{` 1.2.0`}</li>
      <li parentName="ul"><strong parentName="li">{`Atualizado em:`}</strong>{` 15 de julho de 2024`}</li>
    </ul>
    <p>{`Faça o download da versão para Linux x86 `}<a parentName="p" {...{
        "href": "https://storage.cmr.dev.br/cmr-protected-linux-x86.zip?_=1721055600"
      }}>{`aqui`}</a>{`.`}</p>
    <hr></hr>
    <p>{`© 2024 CMR Services. Todos os direitos reservados.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      